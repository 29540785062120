import { ApiResponse,ApiResponseStatus } from "@/Models/Response";
import { IpInfo } from "@/Models/IpInfo";
import  Store  from "@/store/index";
import { HttpClient } from "./HttpClient";
import { EncHelper } from './EncHelper';
import { from } from 'linq-to-typescript';
export class WebApi {
    private static httpClient = new HttpClient();
    private static Base_Url = "https://lt.tinker.ly/api/";
  // private static Base_Url = "https://alphalt.tinker.ly/api/";
   // private static Base_Url = "https://localhost:44300/api/";
    static async sendOTP(mobile:string): Promise<boolean>{
      
       let form = new FormData();
       form.append("Mobile",mobile);
      let response = await this.httpClient.post<ApiResponse>(this.Base_Url+"admin/SendOtp",form);
      if(response!=null && response.status.code == ApiResponseStatus.Success)
      return response.status.code == ApiResponseStatus.Success;
      return false;
    }

    static async Login(mobile:string,otp:string,DeviceId:string,DeviceType:string): Promise<boolean>{
       let ip = Store.state.Ipinfo;
        let location = "IP - "+ip?.ip+",country - "+ip?.country
        +",region - "+ip?.region
        +",city - "+ip?.city
        +",loc - "+ip?.loc
        +",postal - "+ip?.postal
        +",timezone - "+ip?.timezone;
        let form = new FormData();
        form.append("Mobile",mobile);
        form.append("otp",otp);
        form.append("other",location);
        form.append("OS","2");
        form.append("DeviceType",DeviceType);
        form.append("DeviceId",DeviceId);
        let response = await this.httpClient.post<ApiResponse>(this.Base_Url+"admin/LoginWithoutPassword",form) ;
        if(response!=null && response.status.code == ApiResponseStatus.Success)
          {
            Store.state.User =  response.data;
            return true;
          }
          return false;
          
        };

        static async GetSessionListPartner(): Promise<any>{
      
          let form = new FormData();
          form.append("Token",EncHelper.getToken());
         let response = await this.httpClient.post<ApiResponse>(this.Base_Url+"admin/GetSessionForPartner",form);
         if(response!=null && response.status.code == ApiResponseStatus.Success)
         return response.data;
         
       }
       static async CheckStudentExistMarketing(mobile:string,ccode:string): Promise<any>{
      
        let form = new FormData();
        form.append("Token",EncHelper.getToken());
        form.append("Mobile",mobile);
        form.append("CCode",ccode);
       let response = await this.httpClient.post<ApiResponse>(this.Base_Url+"admin/CheckStudentExistMarketing",form);
       if(response!=null && response.status.code == ApiResponseStatus.Success)
       return response.data;
       
     }
    static async GetAvailableSlotMarketing(mobile: string, CCode: string, Session: string, Mode: string, Language: string): Promise<any> {

        let form = new FormData();
        form.append("Token", EncHelper.getToken());
        form.append("Mobile", mobile);
        form.append("CCode", CCode);
        form.append("Mode", Mode);
        form.append("Id", Session);
        form.append("Language", Language);

        let response = await this.httpClient.post<ApiResponse>(this.Base_Url + "admin/GetAvailableSlotsMarketing", form);
        if (response != null && response.status.code == ApiResponseStatus.Success)
            return response.data;

    }
   static async GetStudentCourseStatus(id:string): Promise<any>{
    let form = new FormData();
    form.append("Token",EncHelper.getToken());
    form.append("Id",id);
   let response = await this.httpClient.post<ApiResponse>(this.Base_Url+"admin/GetStudentCourseStatus",form);
   if(response!=null && response.status.code == ApiResponseStatus.Success)
   return response.data;
 }
 static async UpdateAssignmentStatus(id:string,success:string): Promise<any>{
  let form = new FormData();
  form.append("Token",EncHelper.getToken());
  form.append("Id",id);
  form.append("Status",success);
 let response = await this.httpClient.post<ApiResponse>(this.Base_Url+"admin/UpdateAssignmentStatus",form);
 if(response!=null && response.status.code == ApiResponseStatus.Success)
 return response.data;
}

    static async GetIpInfo():Promise<IpInfo>{
        let response = await this.httpClient.get<IpInfo>("https://ipinfo.io/?token=8e57977f57c9be");
        return response;
    }
  }